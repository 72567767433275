/* Menu main. */
.m-menu-main {
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    float: left;
    margin: 0;
    padding: 0;
  }

  &__link {
    color: $color-default;
    display: block;
    padding: $space-small;
    text-decoration: none;

    .m-menu-main__item--is-active &,
    &:hover,
    &:focus {
      color: $color-primary;
      text-decoration: none;
    }
  }
}
