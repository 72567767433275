/* Button. */
.m-button {
  @include button;

  /* Sizes. */
  &--small {
    font-size: $font-size-small;
    padding: $space-small;
  }

  &--large {
    font-size: $font-size-large;
    padding: ($space-base * 1.5);
  }

  &--no-style {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    height: fit-content;
  }

  &--cross {
    & svg {
      color: $color-primary;
    }
  }
}
