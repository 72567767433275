.m-form {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  background-color: rgba(48, 28, 197, 0.78);
  transform: translateY($space-base);
  opacity: 0;
  transition: translate 0.2s $ease-out-base, opacity 0.2s $ease-out-base;
  visibility: hidden;
  z-index: -1;

  @include breakpoint-down(small) {
    background: white;
  }

  &--reveal {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    z-index: 10001;
  }

  &__content {
    position: relative;
    height: fit-content;
    width: 100%;
    background-color: white;
    max-width: $width-small;
    padding: $space-base * 3 $space-base * 4;

    @include breakpoint-down(small) {
      padding: calc(2 * var(--layout-space)) calc(1.5 * var(--layout-space));
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    & .m-button {
      width: fit-content;
    }
  }

  &__message {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.2s $ease-out-cubic, transform 0.4s $ease-out-quart;

    &--hide {
      opacity: 0;
      transform: translate3d(0, 20px, 0);
    }
  }

  &__succes {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(calc(-50% + 20px));
    padding: $space-base * 3 $space-base * 4;
    transition: opacity 0.2s $ease-out-cubic, transform 0.4s $ease-out-quart;
    visibility: hidden;
    opacity: 0;

    &--reveal {
      visibility: visible;
      opacity: 1;
      transform: translateY(-50%);
      transition: opacity 0.2s $ease-out-cubic, transform 0.4s $ease-out-quart;
      transition-delay: 0.6s;
    }
  }

  &__icons {
    display: flex;
    justify-content: space-between;
  }

  &__input {
    display: block;
  }

  & svg {
    color: $color-secondary;
  }
}
