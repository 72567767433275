/* Layout. */
.t-layout {
  /* Spacing. */
  &--inset-collapse {
    padding-top: 0;
    padding-bottom: 0;
  }

  &--inset-small {
    padding-top: $space-small;
    padding-bottom: $space-small;
  }

  &--inset-large {
    padding-top: $space-large;
    padding-bottom: $space-large;
  }

  &--inset-container {
    padding-top: $space-container * 0.5;
    padding-bottom: $space-container * 0.5;

    @include breakpoint-up(medium) {
      padding-top: $space-container * 0.75;
      padding-bottom: $space-container * 0.75;
    }

    @include breakpoint-up(large) {
      padding-top: $space-container;
      padding-bottom: $space-container;
    }
  }

  /* Primary. */
  &--is-primary {
    color: white;
    background-color: $color-primary;

    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      color: white;
    }

    & p,
    a {
      color: white;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: rgba(white, 0.6);
        text-decoration: none;
      }
    }
  }

  /* Light. */
  &--is-light {
    background-color: tint($color-default, 90%);
  }

  &--theme {
    &-primary {
      background-color: $color-primary;
      color: white;
    }

    &-white {
      background-color: white;
      color: $color-default;

      & svg {
        fill: $color-secondary;
      }
    }

    &-grey {
      background-color: $color-grey;
      color: $color-default;
    }
  }
}
