/* Icon */
.m-icon {
  @include icon;

  margin: -1em .2em;
  position: relative;
  top: -.1em;

  /* Direction */
  &--direction-top {
    transform: rotate(270deg);
  }

  &--direction-bottom {
    transform: rotate(90deg);
  }

  &--direction-left {
    transform: rotate(180deg);
  }

  &--direction-right {
    transform: rotate(0deg);
  }

  /* Sizes */
  &--small {
    height: $font-size-base;
    width: $font-size-base;
  }

  &--large {
    height: calc(#{$font-size-base} * 2);
    width: calc(#{$font-size-base} * 2);
  }
}
