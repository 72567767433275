/* Basic grid, based on columns. */
@mixin grid($columns: 1, $rows: auto, $gutter: $gutter-base, $align-x: normal, $align-y: normal) {
  align-items: $align-y;
  display: grid;
  grid-gap: $gutter;
  grid-template-columns: repeat($columns, 1fr);
  grid-template-rows: $rows;
  justify-content: $align-x;
}

/* Responsive grid. */
@mixin grid-auto(
  $min-width: (
    $width-tiny / 2,
  ),
  $gutter: $gutter-base,
  $align-x: normal,
  $align-y: normal
) {
  @include grid(1, auto, $gutter, $align-x, $align-y);

  grid-template-columns: repeat(auto-fit, minmax(#{$min-width}, 1fr));
}
