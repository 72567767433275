/* Align left. */
.h-align--left {
  float: none;
  margin-bottom: $space-base;
  margin-top: $space-base;
  max-width: 100%;

  @include breakpoint-up(tiny) {
    float: left;
    margin-right: $space-base;
    max-width: 50%;
  }
}

/* Align right. */
.h-align--right {
  float: none;
  max-width: 100%;

  @include breakpoint-up(tiny) {
    float: right;
    margin-left: $space-base;
    max-width: 50%;
  }
}
