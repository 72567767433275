/* Logo. */
.m-logo {
  display: block;
  height: auto;
  max-width: 60px;
  width: 100%;

  &__image {
    height: 100%;
    width: 100%;
  }
}
