/* Breadcrumb. */
.m-breadcrumb {
  &__list {
    font-size: 0;
    list-style: none;
    margin: 0;
    overflow: hidden;
    overflow-x: auto;
    padding: 0;
    white-space: nowrap;
  }

  &__item {
    display: inline-block;
    font-size: $font-size-small;
    position: relative;
    padding: 0 $space-small 0 0;
    position: relative;
    margin: 0 $space-small 0 0;

    &:not(:last-child)::after {
      color: tint($color-default, 30%);
      content: '/';
      display: block;
      padding: $space-small 0;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(50%);
    }
  }

  &__link {
    color: tint($color-default, 30%);
    display: block;
    padding: $space-small 0;

    &:hover {
      color: $color-primary;
    }
  }
}
