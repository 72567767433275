/* Button. */
@mixin button($bg: $color-secondary, $border-color: $color-secondary, $color: $color-default, $hover-bg: tint($color-secondary, 20%), $hover-border-color: tint($color-secondary, 20%), $hover-color: $color-default) {
  background: $bg;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  color: $color;
  cursor: pointer;
  display: inline-block;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  line-height: $line-height-small;
  margin: 0;
  outline: none;
  padding: $space-base $space-base * 1.5;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;

  &:hover,
  &:focus,
  &:active {
    background: $hover-bg;
    border-color: $hover-border-color;
    color: $hover-color;
  }

  &:focus,
  &:active {
    box-shadow: inset 0 1px 5px rgba(black, 0.1);
  }

  &:disabled {
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: 0.2;
    pointer-events: none;
  }
}
