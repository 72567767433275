/* Document. */
*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  @include responsive-type;
  --layout-space: calc((100vw - (#{$space-base} * 2)) / #{$layout-columns});

  @include breakpoint-up(giant) {
    --layout-space: calc(calc((#{$layout-width} - (#{$space-base} * 2)) / #{$layout-columns}));
  }
}

html {
  color: $color-default;
  font-family: $font-family-default;
  font-weight: $font-weight-base;
  height: 100%;
  line-height: $line-height-base;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;

  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden !important;
}

/* HTML 5 */
figcaption,
figure,
main {
  display: block;
}
