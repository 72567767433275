/* Hide utilities. */
.u-hide {
  display: none !important;
}

/* Hide @only. */
.u-hide--at-tiny {
  @include breakpoint-only(tiny) {
    display: none !important;
  }
}
.u-hide--at-small {
  @include breakpoint-only(small) {
    display: none !important;
  }
}
.u-hide--at-medium {
  @include breakpoint-only(medium) {
    display: none !important;
  }
}
.u-hide--at-large {
  @include breakpoint-only(large) {
    display: none !important;
  }
}
.u-hide--at-huge {
  @include breakpoint-only(huge) {
    display: none !important;
  }
}

/* Hide @min. */
.u-hide--at-min-tiny {
  @include breakpoint-up(tiny) {
    display: none !important;
  }
}
.u-hide--at-min-small {
  @include breakpoint-up(small) {
    display: none !important;
  }
}
.u-hide--at-min-medium {
  @include breakpoint-up(medium) {
    display: none !important;
  }
}
.u-hide--at-min-large {
  @include breakpoint-up(large) {
    display: none !important;
  }
}
.u-hide--at-min-huge {
  @include breakpoint-up(huge) {
    display: none !important;
  }
}

/* Hide @max. */
.u-hide--at-max-tiny {
  @include breakpoint-down(tiny) {
    display: none !important;
  }
}
.u-hide--at-max-small {
  @include breakpoint-down(small) {
    display: none !important;
  }
}
.u-hide--at-max-medium {
  @include breakpoint-down(medium) {
    display: none !important;
  }
}
.u-hide--at-max-large {
  @include breakpoint-down(large) {
    display: none !important;
  }
}

/* Show utilities. */
.u-show {
  display: block !important;
}

/* Show @only. */
.u-show--at-tiny {
  display: none;

  @include breakpoint-only(tiny) {
    display: block !important;
  }
}
.u-show--at-small {
  display: none;

  @include breakpoint-only(small) {
    display: block !important;
  }
}
.u-show--at-medium {
  display: none;

  @include breakpoint-only(medium) {
    display: block !important;
  }
}
.u-show--at-large {
  display: none;

  @include breakpoint-only(large) {
    display: block !important;
  }
}
.u-show--at-huge {
  display: none;

  @include breakpoint-only(huge) {
    display: block !important;
  }
}
