@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../../fonts/IBMPlexSans-Regular.woff2') format('woff2'), url('../../fonts/IBMPlexSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../../fonts/IBMPlexSans-Bold.woff2') format('woff2'), url('../../fonts/IBMPlexSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
