/* Font family. */
$font-family-default: 'IBM Plex Sans', sans;
$font-family-heading: 'IBM Plex Sans', sans;

/* Font size. */
$font-size-min: 14px;
$font-size-max: 16px;
$font-size-base: 1rem;
$font-size-small: ($font-size-base);
$font-size-medium: ($font-size-base * 1.125);
$font-size-large: ($font-size-base * 1.25);

$font-size-h1: ($font-size-base * 3);
$font-size-h2: ($font-size-base * 2);
$font-size-h3: ($font-size-base * 1.25);
$font-size-h4: ($font-size-base * 1.125);
$font-size-h5: ($font-size-base * 1);
$font-size-h6: ($font-size-base * 1);

/* Font weight. */
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;

/* Line height. */
$line-height-base: 1.5;
$line-height-small: 1.125;

/* Color. */
$color-default: #291e7c;
$color-default-light: #601fdf;
$color-primary: #301cc5;
$color-primary-light: #B5ABFF;
$color-secondary: #58ff7b;
$color-tertiary: #dd154d;

$color-highlight: #009adb;
$color-link: $color-highlight;
$color-border: #6d5bf0;
$color-grey: #f4f5f5;

$color-primary-text: #B5ABFF;

$color-success: #6abb4f;
$color-danger: #e41c33;
$color-warning: #f7b239;

/* Outline. */
$outline: 1px dashed currentColor;

/* Border radius. */
$border-radius: 10px;

/* Space. */
$space-base: $font-size-base;
$space-small: ($space-base * 0.5);
$space-large: ($space-base * 2);
$space-huge: ($space-base * 3);
$space-container: $space-base * 6;

/* Width. */
$width-tiny: 560px;
$width-small: 768px;
$width-medium: 992px;
$width-large: 1200px;
$width-huge: 1440px;
$width-giant: 1800px;
$width-mega: 2200px;
$width-base: $width-medium;

/* Gutter. */
$gutter-base: $space-base;
$gutter-small: ($gutter-base * 0.5);
$gutter-large: ($gutter-base * 2);

/* Breakpoints. */
$breakpoints: (
  nano: 0px,
  tiny: $width-tiny,
  small: $width-small,
  medium: $width-medium,
  large: $width-large,
  huge: $width-huge,
  giant: $width-giant,
  mega: $width-mega,
);

$layout-columns: 24;
$layout-width: $width-base;