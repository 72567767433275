/* Header. */
.o-header {
  position: relative;

  &__content {
    align-items: center;
    display: flex;
    top: 50%;
    max-height: 624px;
  }

  &__logo {
    margin-right: $space-base;
  }

  &--full {
    padding: 12rem 0;
    min-height: 100vh;

    & .t-container,
    .t-grid {
      height: 100%;
    }

    @include breakpoint-down(small) {
      padding: 16rem 0;
    }
  }

  &__bg {
    height: 100vh;
    left: 0;
    background: $color-primary;
    object-fit: cover;
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 100%;

    .o-header__video {
      position: absolute;
      left: 50%;
      top: 5%;
      transform: translate3d(-50%, 0, 0);
      width: 550px;
      height: auto;
      z-index: 1;

      @include breakpoint-down(tiny) {
        width: 100%;
        top: 10%;
      }

      @include breakpoint-up(mega) {
        top: 10%;
      }

      &--bg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        object-fit: cover;

        @include breakpoint-down(small) {
          object-fit: fill;
        }
      }
    }

    &::after {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      content: '';
      background: linear-gradient(180deg, #301cc5 14.62%, transparent 33%);
      transform: rotate(-180deg);
    }
  }

  &__content {
    text-align: center;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 2;
  }

  &__text {
    margin-top: $space-huge;
  }

  &__arrow {
    position: absolute;
    left: 50%;
    bottom: 1.5rem;
    transform: translate3d(-50%, 0, 0);

    @include breakpoint-down(small) {
      bottom: 4rem;
    }
  }

  &--404 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100vh;

    padding: 0;

    &:before {
      content: "";
      height: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      background: radial-gradient(48.1% 95.53% at 100.27% 72%,#5e10ff 0,#301cc5 100%);
    }

    & .o-header__content {
      display: flex;
      flex-direction: column;
      gap: $space-base;

      & a {
        color: white;
      }

      & h1 {
        font-size: 86px;
        color: white;
      }

      & p {
        font-size: 24px;
        color: white;
      }
    }
  }
}

