/* Search. */
.m-search {

}

/* Search form. */
.m-search-form {
  &__inner {
    display: flex;
    position: relative;
  }

  &__submit {
    flex: 1 0 auto;
    margin-left: $space-small;
  }
}

/* Search results. */
.m-search-results {
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    border-bottom: 1px solid tint($color-default, 90%);
    margin: 0;
    padding: $space-small 0;

    &:first-child {
      border-top: 1px solid tint($color-default, 90%);
    }
  }
}
