/* Heading */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-primary;
  font-family: $font-family-heading;
  font-weight: $font-weight-bold;
  hyphens: auto;
  line-height: $line-height-small;
  margin: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;

  @include breakpoint-up(small) {
    hyphens: manual;
    overflow-wrap: normal;
    word-wrap: normal;
  }
}

h1 {
  font-size: calc(#{$font-size-base} * 2.5);
  letter-spacing: -0.02em;

  @include breakpoint-up(small) {
    font-size: $font-size-h1;
  }
}
h2 {
  font-size: $font-size-h2;
}
h3 {
  font-size: $font-size-h3;
  font-weight: $font-weight-bold;
  margin: ($space-base * 0.25) 0 $space-small;
}
h4 {
  font-size: $font-size-h4;
  margin: 0 0 $space-small;
}
h5 {
  font-size: $font-size-h5;
  margin: 0 0 $space-small;
}
h6 {
  font-size: $font-size-h6;
  margin: 0 0 $space-small;
}

/* Paragraph */
p {
  margin: 0;
}

/* Hyperlink */
a {
  color: $color-link;
  cursor: pointer;
  text-decoration: underline currentColor;
  text-decoration-skip-ink: auto;

  &:hover,
  &:focus,
  &:active {
    color: shade($color-primary, 40%);
    text-decoration: none;
  }

  &:focus {
    outline: $outline;
    outline-offset: 2px;
  }
}

/* Blockquote */
blockquote {
  border-left: 1px solid $color-default;
  color: $color-primary;
  font-size: $font-size-h2;
  font-weight: $font-weight-bold;
  line-height: $line-height-small;
  margin: $space-small 0 $space-base;
  padding: 0 0 0 $space-base;
}

/* Figure */
figure {
  margin: 0;
}

/* Image */
img {
  image-rendering: -webkit-optimize-contrast;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  aspect-ratio: auto;
}

picture {
  width: 100%;
  height: 100%;

  & img {
    aspect-ratio: auto;
    height: auto;
    max-width: 100%;
  }
}

/* Subscript & Superscript */
sup,
sub {
  font-size: $font-size-small;
}

/* Address */
address {
  font-style: normal;
}

/* Line */
hr {
  border: none;
  border-bottom: 1px solid $color-default;
  height: 1px;
  margin: $space-base 0;
}

/* List */
ul,
ol {
  margin: 0;
}
ul {
  list-style-type: none;
  padding-inline-start: 0px;
}
ol {
  list-style-type: decimal;
}

/* Small */
small {
  font-size: $font-size-small;
}

/* Strong */
strong {
  font-weight: $font-weight-bold;
}
